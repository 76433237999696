.navBar {
  display: flex; /*create a flexbox (style it like a block item)*/
  flex-direction: row;
  align-items: center;
  margin: 0 3%;
  padding: 2em 1%;
  /* border: 1px solid blue; */
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #fffff9;
}

/* Style the links inside the navigation bar */
.navBar a {
  color: black;
  text-align: center;
  padding: 1%;
  text-decoration: none;
}

/* Add a color to the active/current link */
.name {
  color: black;
  font-size: 2.5em;
  font-weight: 300;
}

/* Right-aligned section inside the top navigation */
.menu {
  font-size: 1.5em;
  font-weight: 300;
  overflow: hidden;
  display: flex; /*create a flexbox (style it like a block item)*/
  justify-content: flex-end;
  align-items: center;
  display: -webkit-flex; /* Safari */
  flex-wrap: wrap; /*items in the flexbox will drop down when they do not fit this div*/
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  flex: 1;
  text-decoration-skip-ink: none;
  /* border: 1px solid blue; */
}
.SelectedWritingSection {
  padding: 1%;
  margin-left: 3%;
  margin-right: 3%;
}
.Label {
  font-size: 1.5em;
  padding-right: 2%;
  font-weight: 400;
  min-width: 100px;
  display: inline-block;
}
.Publication {
  padding-bottom: 1%;
}
.PublicationName {
  font-size: 1.5em;
  font-weight: 200;
}
.contactText {
  font-weight: 200;
}
/* .contactPage {
  color: black;
  text-align: center;
  padding: 1vw;
  text-decoration: none;
}


/* contact
*/

.wrap {
  display: flex; /*create a flexbox (style it like a block item)*/
  display: -webkit-flex; /* Safari */
  flex-wrap: wrap; /*items in the flexbox will drop down when they do not fit this div*/
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  justify-content: center; /*spaces content on a row keeping an item on the right and one on the left and creating empty space inbetween unless the next item fits in this space in which case the browser will do that (same effect as float: left, only with a better transition)*/
  align-items: center;
  text-align: center;
  width: 680px;
  max-width: 100%;
  flex-direction: column;
  margin: auto;
  margin-top: 14vh;
}
.memoriaImage img {
  width: 90%;
  margin: auto;
  height: auto;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .menu {
    font-size: 1em;
  }
  .Label {
    font-size: 1em;
    min-width: 75px;
  }
  .name {
    font-size: 1.5em;
  }
  .PublicationName {
    font-size: 1em;
  }
}

a {
  color: black;
  text-decoration-thickness: 0.07em;
}

u {
  text-decoration-thickness: 0.075em;
}
